import { useContext, useEffect, useCallback } from "react";
import { useApolloClient, useQuery } from "@apollo/client";
import { CartContext } from "../../../shared/contexts";
import { ADD_INSURANCE, GET_CART } from "../../graphql/cart-queries";
import { UPDATE_BYOD_NUMBER } from "../../graphql/byod-queries";
import {
  ADD_PRODUCT_TO_CART,
  REMOVE_CART_ITEM,
  UPDATE_CART_ITEMS,
} from "../../graphql/cart-mutations";

/**
 * By default, useCart will _not_ load cart data from graphql.  This is intentional
 * to avoid needlessly fetching data from the backend server when components may
 * just want to have some mutation methods available.  To load the cart data from
 * graphql immediately when using this hook, pass {skipCartQuery: false}.
 */
function useCart({ skipCartQuery = true } = { skipCartQuery: true }) {
  const {
    cart,
    headline,
    cartSummary,
    cleanCart,
    isCartInitialized,
    isTradeInApproved,
    cartProductInfoValidate,
    removedItems,
    refreshCartContext,
    qualificationStatus,
    updateCartContext,
    updateHeadlineContext,
    updateTradeInStatus,
    clearCart,
    updateQualificationStatus,
    changeCartProductInfoValidate,
  } = useContext(CartContext);

  const shouldSkipQuery =
    skipCartQuery && (cart?.id === undefined || cart?.id === null);

  const { data: cartData, loading: isCartLoading } = useQuery(GET_CART, {
    skip: shouldSkipQuery,
    variables: { cartId: cart?.id },
    fetchPolicy: "network-only",
  });

  const client = useApolloClient();

  const refreshCart = useCallback(async () => {
    console.log("cart id in use cart", cart?.id);
    await refreshCartContext();
  }, [refreshCartContext]);

  useEffect(() => {
    if (cartData?.cart && !isCartLoading) {
      updateCartContext(cartData.cart);
    }
  }, [cartData, isCartLoading, updateCartContext]);

  function updateHeadline(content) {
    updateHeadlineContext(content);
  }

  function updateTradeInVerified(status) {
    updateTradeInStatus(status);
  }

  function findItemInCart(item) {
    const foundItem = {quantity: 0, cart_item_id: 0};

    cart.items.forEach((cartItem) => {
      if (cartItem.product?.categories && cartItem.product?.categories[0]?.canonical_url === "accessories" &&
        (cartItem.product?.sku === item.data?.sku || cartItem.simple_product_sku === item.data?.sku)) {
        foundItem.quantity = cartItem.quantity + 1;
        foundItem.cart_item_id = parseInt(cartItem?.id, 10);
      }
    })

    return foundItem;
  }

  async function addToCart(item) {
    const itemInCart = findItemInCart(item);

    if (itemInCart.quantity === 0 && itemInCart.cart_item_id === 0) {
      const { data, errors } = await client.mutate({
        mutation: ADD_PRODUCT_TO_CART,
        variables: {
          input: {
            cart_id: cart.id,
            cart_items: [item],
          },
        },
      });

      if (data?.addConfigurableProductsToCart?.cart) {
        updateCartContext(data.addConfigurableProductsToCart.cart);
      }

      if (errors) {
        throw errors;
      }

      return data;
    } else {
      return await updateCartQuantity(itemInCart);
    }
  }

  async function updateCartQuantity(item) {
    const { data, errors } = await client.mutate({
      mutation: UPDATE_CART_ITEMS,
      variables: {
        input: {
          cart_id: cart?.id,
          cart_items: [item],
        },
      },
    });

    if (data?.updateCartItems?.cart) {
      updateCartContext(data.updateCartItems.cart);
      changeCartProductInfoValidate(true);
    }

    if (errors) {
      throw errors;
    }

    return data;
  }

  async function addBYODToCart(item) {
    const { data } = await client.mutate({
      mutation: UPDATE_BYOD_NUMBER,
      variables: {
        cart_id: item?.cart_id,
        package_id: item?.package_id,
        phone_value: item?.phone_value,
        plan_sku: item?.plan_sku,
        sim_type: item?.sim_type,
        device_title: item?.device_title,
        device_image: item?.device_image,
        imei: item?.imei,
      },
    });
    if (data?.addSimpleProductsToCart?.cart) {
      updateCartContext(data.addSimpleProductsToCart.cart);
    }
  }

  async function removeItemFromCart(packageId) {
    const { data } = await client.query({
      query: REMOVE_CART_ITEM,
      variables: {
        package_id: packageId,
        cartId: cart?.id,
      },
    });
    if (data.getDeviceDelete[0].message === "Cart Item Deleted") {
      updateCartContext(data.cart);
    }
  }

  async function addBoostProtect(id) {
    const { data } = await client.mutate({
      mutation: ADD_INSURANCE,
      variables: {
        package_id: id,
      },
    });

    if (data?.addInsuranceInCart?.status) {
      refreshCart(true);
    }
  }

  return {
    addToCart,
    updateCartQuantity,
    addBYODToCart,
    removeItemFromCart,
    cart,
    cleanCart,
    headline,
    isTradeInApproved,
    cartSummary,
    isCartInitialized,
    cartProductInfoValidate,
    removedItems,
    qualificationStatus,
    refreshCart,
    updateCart: updateCartContext,
    updateHeadlineContext,
    updateHeadline,
    updateTradeInStatus,
    updateTradeInVerified,
    addBoostProtect,
    clearCart,
    updateQualificationStatus,
    changeCartProductInfoValidate,
  };
}

export default useCart;
